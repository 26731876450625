<template>
  <!-- 垂直菜单栏的组件 -->
  <div class="left_menu" v-if="menus.length > 0">
    <el-menu class="sidebar_menu" :default-active="active" router text-color="#fff" active-text-color="#069BBC" background-color="transparent">
      <div v-for="(item, index) in menus" :key="index">
        <!-- 有三级菜单 -->
        <el-submenu v-if="item.children.length > 0" :index="item.menuPath">
          <template slot="title">{{ item.menuName }}</template>
          <el-menu-item v-for="(items, index) in item.children" :key="index" :index="items.menuPath">
            <span slot="title">{{ items.menuName }}</span>
          </el-menu-item>
        </el-submenu>

        <!-- 无三级菜单 -->
        <el-menu-item v-if="item.children.length <= 0" :index="item.menuPath">
          <span slot="title">{{ item.menuName }}</span>
        </el-menu-item>
      </div>
    </el-menu>
  </div>
</template>

<script>
export default {
  props: {
    menus: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      active: null,
      isBrowserBackButtonClicked: false,
    };
  },
  created () {
    // 存储url参数，防止被重定向后丢失
    if (Object.keys(this.$route.query).length != 0) localStorage.setItem('param', JSON.stringify(this.$route.query))
    // 页面初始化时，检查 storage 是否有详情页路径
    const lastDetailPath = localStorage.getItem('lastDetailPath');
    if (lastDetailPath) {
      this.active = this.$store.state.isDetail
      this.$router.push(lastDetailPath);
    } else {
      if (this.menus.length == 0) {
        this.active = '/'
        this.$router.push({ path: this.active })
      } else {
        if (this.$store.state.sideMenuActive != null) {
          this.active = this.$store.state.sideMenuActive
          this.$router.push({ path: this.active })
        } else {
          let list = this.menusRecursion(this.menus)
          this.active = list[0].menuPath
          this.$router.push({ path: this.active })
        }
      }
    }
  },
  mounted () {
    // 在组件的 mounted 钩子中监听 popstate(浏览器自带前进后退事件) 事件
    window.onpopstate = () => {
      this.isBrowserBackButtonClicked = true;
    };
  },
  watch: {
    // 根据路由判断切换二级菜单
    $route (to, from) {
      // 切换具有菜单标识的路由
      if (this.$route.meta.isMenu) {
        this.active = to.path;
        this.$store.commit("setSideMenuActive", to.path);
        this.$store.commit("setIsDetail", null);
        localStorage.removeItem('lastDetailPath')
      } else {
        this.active = this.$store.state.isDetail
      }

      // 用户进入详情页
      if (to.path.includes("/detail/")) {
        localStorage.setItem('lastDetailPath', to.fullPath)
        // 上一页不为详情时，缓存上一页路由（防止多级详情互跳）
        if (this.$store.state.isDetail == null && !from.path.includes('/detail/')) this.$store.commit("setIsDetail", from.path);
        // 菜单跟随缓存跳转
        this.active = this.$store.state.isDetail;
        this.$store.commit("setSideMenuActive", this.$store.state.isDetail);
      }

      // 用户点击了浏览器自带的前进、后退
      if (this.isBrowserBackButtonClicked) {
        // 关闭点击事件
        this.isBrowserBackButtonClicked = false;
      }
    },
  },
  methods: {
    // 递归menus，直到children长度为0时，取当前这一层数据的menuPath为初始选中的菜单
    menusRecursion (menus) {
      const children = [];
      menus.forEach((item) => {
        if (item.children && item.children.length > 0) {
          item.children.forEach((child) => {
            children.push(child);
          });
        } else {
          children.push(item);
        }
      });
      return children;
    },
  },
};
</script>

<style scoped lang="scss">
.left_menu {
  width: 100%;
  height: 100%;

  .el-menu {
    position: relative;
    width: 136px !important;
    height: 100%;
    border: none !important;

    /deep/ .el-submenu__title,
    .el-menu-item {
      height: 44px !important;
      line-height: 44px !important;
      border-radius: 22px 0 0 22px !important;
      padding: 0 0 0 12px !important;
    }

    .el-submenu .el-menu-item {
      min-width: 0;
      padding: 0 0 0 18px !important;
    }

    /deep/ .el-submenu__title:hover,
    .el-menu-item:hover {
      background-color: rgba(255, 255, 255, 0.3) !important;
    }

    .el-menu-item.is-active {
      background-color: #fff !important;
    }

    /deep/.el-icon-arrow-down {
      color: #fff !important;
    }

    .el-menu-item,
    .el-submenu,
    ::v-deep .el-submenu__title {
      font-size: 16px !important;
    }

    .el-submenu {
      padding: 0 !important;
    }

    .el-menu-item.is-active {
      color: #fff;
      background-color: #069BBC;
    }
  }
}
</style>
